import React, { useState } from "react";
import style from "./Commande.module.css";
import translate from "../../locales/fr-translation.json";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EmailIcon from "@mui/icons-material/Email";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { Order } from "../../redux/interfaces/interfaces";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  useCancelOrderAdminMutation,
  useAccepteOrderMutation,
  useShipOrderMutation,
} from "../../redux/apiSlice";
import LodingPage from "../LodingPage/LodingPage";
import {
  setErrorSnackBar,
  setSuccessSnackBar,
} from "../../redux/reducers/snackbar";
import axios from "axios";
import { handleOpen } from "../../redux/reducers/dialog";
import { setSuccess, setPage } from "../../redux/reducers/fetchedOrders";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import logo from "../../assets/images/logo.webp";

interface Props {
  order: Order;
}

const { REACT_APP_BACK_URI } = process.env;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(253, 237, 237)",
    color: "#fff",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #ef5350",
  },
}));

const Commande: React.FC<Props> = ({ order }) => {
  const dispatch = useDispatch();
  const [downloadLoding, setDownloadLoding] = useState(false);
  let getFile = (
    type: string,
    idToken: string | null,
    oid: string | null,
    accountType: String
  ) => {
    try {
      setDownloadLoding(true);
      axios({
        method: "get",
        url: `${REACT_APP_BACK_URI}/${accountType}/orders/${type}-label/${oid}`,
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": true,
          Authorization: `Bearer ${idToken}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              type == "packing"
                ? "Bordereau d'emballage"
                : "Étiquette d'expedition"
            }.pdf`
          );
          document.body.appendChild(link);
          link.click();
          setDownloadLoding(false);
        })
        .catch((error) => {
          dispatch(
            setErrorSnackBar(
              "Une erreur s'est produite lors du téléchargement de page."
            )
          );
          setDownloadLoding(false);
        });
    } catch (error) {}
  };

  const accounttype = useSelector(
    (state: RootState) => state.account.accountType
  );
  const [accepteOrder, resultAccepteOrder] = useAccepteOrderMutation();
  const [shipOrder, resultShipOrder] = useShipOrderMutation();
  let hide = false;

  if (resultAccepteOrder.isSuccess) {
    dispatch(setSuccess());
    dispatch(setSuccessSnackBar(translate.Commandes.accepteSuccessMessage));
  } else if (resultAccepteOrder.isError) {
    const errorData = JSON.stringify(resultAccepteOrder.error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(
      setErrorSnackBar(
        errorDataJson.error ? errorDataJson.error : errorDataJson.data.message
      )
    );
  }

  if (resultShipOrder.isSuccess) {
    dispatch(setSuccess());
    dispatch(setSuccessSnackBar(translate.Commandes.shippedSuccessMessage));
  } else if (resultShipOrder.isError) {
    const errorData = JSON.stringify(resultShipOrder.error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(
      setErrorSnackBar(
        errorDataJson.error ? errorDataJson.error : errorDataJson.data.message
      )
    );
  }

  let iconType = <InfoOutlinedIcon />;
  let colorType:
    | "error"
    | "default"
    | "success"
    | "primary"
    | "secondary"
    | "info"
    | "warning"
    | undefined;
  let textType: string = "REÇUES";

  switch (order.status) {
    case "RECEIVED":
      iconType = <InfoOutlinedIcon />;
      colorType = "primary";
      textType = "REÇUES";
      break;
    case "ACCEPTED":
      iconType = <CheckBoxIcon />;
      colorType = "success";
      textType = "ACCÉPTÉE";
      break;
    case "SHIPPED":
      iconType = <EmailIcon />;
      colorType = "secondary";
      textType = "EXPÉDIÉE";
      break;
    case "DECLINED":
      iconType = <WarningIcon />;
      colorType = "error";
      textType = "ANNULÉE";
      break;

    default:
      iconType = <InfoOutlinedIcon />;
      colorType = "primary";
      textType = "REÇUES";
      break;
  }

  //  for the annuler dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      className={
        hide ? `${style.hide} ${style.mainContainer}` : style.mainContainer
      }
    >
      {/* {
            resultCancelOrder.isLoading?
            <LodingPage />:
            <></>
        } */}
      {downloadLoding ? <LodingPage /> : <></>}
      <div>
        <CardActions>
          <Stack direction="row" spacing={1} className={style.chips}>
            <div>
              <Chip
                avatar={<Avatar>F</Avatar>}
                label={order.distributor}
                color="primary"
              />
              <Chip
                icon={iconType}
                label={textType}
                color={colorType}
                className={style.chipType}
              />
            </div>
            {accounttype ? (
              <></>
            ) : (
              <>
                {order.error ? (
                  <HtmlTooltip
                    arrow
                    title={
                      <Alert severity="error">
                        <AlertTitle>Erreur</AlertTitle>
                        {order.error}
                      </Alert>
                    }
                  >
                    <ErrorIcon
                      color="error"
                      fontSize="large"
                      className={style.errorIcon}
                    />
                  </HtmlTooltip>
                ) : (
                  <></>
                )}
              </>
            )}
          </Stack>
        </CardActions>
        <CardHeader
          className={style.header}
          title={order.title}
          subheader={
            "Reçue le " +
            new Date(order.orderedAt).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }
          action={
            <Stack direction="row" className={style.headerAction}>
              <Button
                variant="text"
                onClick={() => {
                  getFile(
                    "packing",
                    localStorage.getItem("profile"),
                    (order.oid as unknown) as string,
                    accounttype ? "distributor" : "admin"
                  );
                }}
              >
                Bordereau d'emballage
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  getFile(
                    "shipping",
                    localStorage.getItem("profile"),
                    order.shippingLabel,
                    accounttype ? "distributor" : "admin"
                  );
                }}
              >
                Étiquette d'expedition
              </Button>
            </Stack>
          }
          titleTypographyProps={{ align: "left" }}
          subheaderTypographyProps={{ align: "left" }}
        />
        <CardContent>
          <Stack direction="column">
            <p className={style.text}>Adresse: {order.address}</p>
            <p className={style.text}>Email: {order.email}</p>
            <p className={style.text}>Téléphone: {order.phoneNumber}</p>
          </Stack>
        </CardContent>
        <CardContent>
          <Stack direction="row">
            <img
              src={order?.products[0]?.pictureUrl || logo}
              alt="product"
              className={style.orderImage}
            />
            <Stack direction="column" className={style.productTexts}>
              {order.products.map((product: any) => (
                <Tooltip
                  title={product.description}
                  placement="top-start"
                  arrow
                  key={product.description}
                >
                  <p className={style.productText}>• {product.description}</p>
                </Tooltip>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </div>
      <CardActions>
        <Stack direction="row" spacing={1}>
          {accounttype ? (
            <>
              {order.status == "DECLINED" ? (
                <></>
              ) : (
                <>
                  {order.status == "RECEIVED" ? (
                    <>
                      <Button
                        variant="text"
                        onClick={() => {
                          accepteOrder({ oid: order.oid });
                        }}
                      >
                        Accepter
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          shipOrder({ oid: order.oid });
                        }}
                      >
                        Marquer comme expédiée
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          dispatch(
                            handleOpen({
                              oid: order,
                              accountType: accounttype
                                ? "distributor"
                                : "admin",
                            })
                          );
                        }}
                      >
                        Annuler
                      </Button>
                    </>
                  ) : (
                    <>
                      {order.status == "ACCEPTED" ? (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              shipOrder({ oid: order.oid });
                            }}
                          >
                            Marquer comme expédiée
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              dispatch(
                                handleOpen({
                                  oid: order,
                                  accountType: accounttype
                                    ? "distributor"
                                    : "admin",
                                  isAccept: true,
                                })
                              );
                            }}
                          >
                            Annuler
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {order.status == "DECLINED" ? (
                <></>
              ) : (
                <Button
                  variant="text"
                  onClick={() => {
                    dispatch(
                      handleOpen({
                        oid: order,
                        accountType: accounttype ? "distributor" : "admin",
                      })
                    );
                  }}
                >
                  Annuler
                </Button>
              )}
            </>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
};

export default Commande;
